import React from "react"

const ProductGuide = () => (
  <a
    className="product-guide-link"
    href="https://productguide.alfalaval.com/?companyId=c190&language=pl-PL&country=PL"
  >
    <div className="product-guide">
      <div className="product-guide__text">
        <h3>Znajdź odpowiedni wymiennik </h3>za pomocą wyszukiwarki Product
        Guide
      </div>
    </div>
  </a>
)

export default ProductGuide
