import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

import Container from "../../components/Container/Container"
import Product from "../../components/Product/Product"
import ProductHeader from "../../components/Product/ProductHeader"
import ProductDescription from "../../components/Product/ProductDescription"
import ProductDocs from "../../components/Product/ProductDocs"
import ProductGuide from "../../components/Product/ProductGuide"

import ProductDocsList from "../../components/Product/ProductDocsList"
import ProductAccordionListItem from "../../components/Product/ProductAccordion/ProductAccordionListItem"

import wykres from "../../images/products/alfanova/alfanova-wykres.png"

const AlfaNova = () => {
  const { alfanova } = useStaticQuery(
    graphql`
      query {
        alfanova: file(relativePath: { eq: "products/alfanova.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <Layout pageInfo={{ pageName: "alfa-nova" }}>
      <SEO title="Alfa Nova" />
      <Container>
        <Product>
          <ProductHeader headerImage={alfanova.childImageSharp.fluid}>
            Alfa Laval AlfaNova jest pierwszym płytowym wymiennikiem ciepła na
            świecie, wykonanym całkowicie ze stali kwasoodpornej. Jest to
            możliwe dzięki unikalnej technologii dyfuzyjnego łączenia płyt,
            opatentowanej przez Alfa Laval.
          </ProductHeader>
          <ProductDocs>
            <ProductDocsList title="Karty katalogowe">
              <ProductAccordionListItem linkTo="/alfanova/alfanova-broszura.pdf">
                Wymienniki ciepła AlfaNova wykonane ze stali kwasoodpornej -
                broszura
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/alfanova/alfa-laval-alfanova-14.pdf">
                AlfaNova 14
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/alfanova/alfa-laval-alfanova-27.pdf">
                AlfaNova 27
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/alfanova/alfa-laval-alfanova-52.pdf">
                AlfaNova 52
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/alfanova/alfa-laval-alfanova-76.pdf">
                AlfaNova 76
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/alfanova/alfa-laval-alfanova-200.pdf">
                AlfaNova 200
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="/alfanova/alfa-laval-alfanova-400.pdf">
                AlfaNova 400
              </ProductAccordionListItem>
            </ProductDocsList>
            <ProductDocsList title="Deklaracje zgodności i atesty">
              <ProductAccordionListItem linkTo="/alfanova/alfanova-deklaracja.pdf">
                Deklaracja zgodności
              </ProductAccordionListItem>
            </ProductDocsList>
          </ProductDocs>
          <ProductDescription>
            <h4 style={{ marginTop: "1rem" }}>
              Zalety wymienników ciepła Alfa Nova:
            </h4>
            <div>
              <ul style={{ minWidth: 285 }}>
                <li>wykonanie w 100% ze stali kwasoodpornej AISI 316;</li>
                <li>
                  możliwość zastosowania w instalacjach higienicznych,
                  farmaceutycznych;
                </li>
                <li>najwyższa wydajność w kompaktowej budowie;</li>
                <li>
                  kompatybilność wymiarów z wymiennikami z serii CB (możliwa
                  zamiana 1:1);
                </li>
                <li>
                  wydłużona żywotność w porównaniu do standardowych wymienników
                  lutowanych miedzią i odporność na czyszczenie chemiczne (CIP);
                </li>
                <li>
                  możliwość montażu w instalacjach wykonanych z rur
                  ocynkowanych;
                </li>
                <li>
                  wysoka odporność temperaturowa (nawet do 550°C) oraz
                  ciśnieniowa (do 30 bar zdodnie z PED);
                </li>
              </ul>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={wykres}
                  style={{ maxWidth: "32rem" }}
                  alt="Wykres ciśnienia/temperatury zgodnie z PED"
                />
              </div>
            </div>
            <h4 style={{ marginTop: "1rem" }}>
              Zastosowania wymienników ciepła Alfa Nova:
            </h4>
            <ul>
              <li>
                instalacje centralnego ogrzewania i CWU (zwłaszcza w obszarach,
                gdzie woda posiada wysokie właściwości korozyjne bądź wysoką
                twardość);
              </li>
              <li>instalacje parowe;</li>
              <li>
                instalacje przemysłowe (np. produkcji paliw, procesy
                technologiczne);
              </li>
              <li>
                instalacje higieniczne (farmacja, instalacje spożywcze itp.);
              </li>
              <li>instalacje chłodnicze (amoniakalne, glikolowe).</li>
            </ul>
            <ProductGuide />
          </ProductDescription>
        </Product>
      </Container>
    </Layout>
  )
}

export default AlfaNova
